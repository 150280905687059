<template lang="pug">
  v-card
    v-toolbar.elevation-0
      v-spacer
      v-btn(
        color="grey"
        icon
        large
        dense
        @click="$emit('close')"
      )
        v-icon close
    v-snackbar(v-model="flags.snackbar" top right) {{ snackbar.text }}
      v-btn(color="pink" text @click="flags.snackbar = false" ) Fechar
    v-form(
      ref="form"
      v-model="form.valid"
    )
      v-stepper(v-model="step" vertical).elevation-0
        v-stepper-items
          v-stepper-step(:complete="step > 1" step=1 editable) Grupos
          v-stepper-content(step=1)
            v-container
              v-row.mb-4
                v-col(cols="auto")
                  v-select(
                    v-model="form.usersTypes"
                    :items="users.types"
                    item-text="name"
                    item-value="slug"
                    label="Tipos de usuários"
                    filled
                    rounded
                    multiple
                    clearable
                    style="max-width: 267px"
                    @change="select"
                    :rules="[rules.recipients]"
                    :disabled="loadings.send"
                  )
                    template(v-slot:selection="{ item, index }")
                      span(
                        v-if="index === 0"
                        class="grey--text caption"
                      ) {{ form.usersTypes.length }} {{ form.usersTypes.length === 1 ? 'seleção' : 'seleções' }}
                v-col(cols="auto")
                  v-autocomplete(
                    v-model="form.groups"
                    :items="filtered.groups"
                    item-text="name"
                    item-value="cod"
                    label="Grupos"
                    filled
                    rounded
                    multiple
                    clearable
                    style="max-width: 300px"
                    @change="select"
                    :rules="[rules.recipients]"
                    :disabled="loadings.send"
                  )
                    template(v-slot:selection="{ item, index }")
                      span(
                        v-if="index === 0"
                        class="grey--text caption"
                      ) {{ form.groups.length }} {{ form.groups.length === 1 ? 'seleção' : 'seleções' }}
                    template(v-slot:append-outer)
                      v-menu(
                        min-width="300px"
                        :close-on-content-click="false"
                      )
                        template(v-slot:activator="{ on }")
                          v-icon(
                            v-on="on"
                            :color="form.groupsTypes.length ? 'primary' : 'grey'"
                          ) filter_list
                        v-list
                          v-list-item-group(
                            v-model="form.groupsTypes"
                            multiple
                          )
                            v-list-item(v-for="type in groups.types" :key="type.slug")
                              template(v-slot:default="{ active, toggle }")
                                v-list-item-action
                                  v-checkbox(
                                    v-model="active"
                                    color="primary"
                                    @click="toggle"
                                  )
                                v-list-item-content
                                  v-list-item-title {{ type.name }}
              v-row
                v-col(cols=12)
                  v-data-table(
                    :headers="[{ text: 'Nome', value: 'name' }, { text: 'Tipo', value: 'type' }]"
                    :items="recipientsData"
                    :items-per-page="5"
                    no-data-text="Nenhum destinatário selecionado"
                  )
              v-row.mt-5
                v-spacer
                v-col(cols="auto")
                  v-btn(
                    depressed
                    color="primary"
                    @click="step++"
                  )
                    span Continuar
          v-stepper-step(:complete="step > 2" step=2 editable) Usuários
          v-stepper-content(step=2)
            v-row
              v-col(cols=12)
                v-autocomplete(
                  v-model="form.users"
                  :items="users.users"
                  item-text="name"
                  item-value="id"
                  label="Destinatários"
                  placeholder="Pesquise ou selecione os destinatários"
                  persistent-hint
                  prepend-icon="person"
                  multiple
                  :rules="[rules.recipients]"
                  :disabled="loadings.send"
                  return-object
                )
            v-row.mt-5
              v-spacer
              v-col(cols="auto")
                  v-btn(
                    depressed
                    color="primary"
                    @click="step--"
                  )
                    span Voltar
              v-col(cols="auto")
                v-btn(
                  depressed
                  color="primary"
                  @click="step++"
                )
                  span Continuar
          v-stepper-step(:complete="step > 3" step=3 editable) Notificação
          v-stepper-content(step=3)
            v-container
              v-row(v-if="loadings.send").mb-5
                v-progress-linear(indeterminate color="primary")
              v-row
                v-col
                  v-text-field(
                    v-model="form.title"
                    counter="45"
                    label="Título da notificação"
                    prepend-icon='mdi-format-title'
                    :disabled="loadings.send"
                    :rules="[rules.required, rules.max]"
                  )
              v-row
                v-col(cols="auto")
                  v-dialog(
                    ref='dateDialog'
                    v-model='flags.date'
                    :return-value.sync='form.date'
                    width='290px'
                  )
                    template(v-slot:activator='{ on }')
                      v-text-field(
                        v-model='formmatedDate'
                        label='Data da notificação'
                        prepend-icon='date_range'
                        readonly
                        v-on='on'
                        :disabled="loadings.send"
                      )
                    v-date-picker(
                      v-if='flags.date'
                      v-model='form.date'
                      full-width
                      locale="pt-br"
                    )
                      v-spacer
                      v-btn(text='' color='primary' @click='flags.date = false') Cancelar
                      v-btn(text='' color='primary' @click='$refs.dateDialog.save(form.date)') Ok
                v-col(cols="auto")
                  v-dialog(
                    ref='timeDialog'
                    v-model='flags.time'
                    :return-value.sync='form.time'
                    width='290px'
                  )
                    template(v-slot:activator='{ on }')
                      v-text-field(
                        v-model='form.time'
                        label='Horário da notificação'
                        prepend-icon='access_time'
                        readonly
                        v-on='on'
                        :disabled="loadings.send"
                      )
                    v-time-picker(
                      v-if='flags.time'
                      v-model='form.time'
                      format="24hr"
                      full-width
                    )
                      v-spacer
                      v-btn(text='' color='primary' @click='flags.time = false') Cancelar
                      v-btn(text='' color='primary' @click='$refs.timeDialog.save(form.time)') Ok
              v-row
                v-textarea(
                  v-model="form.body"
                  label="Corpo da notificação"
                  filled
                  prepend-inner-icon="insert_comment"
                  auto-grow
                  :disabled="loadings.send"
                )
              v-row.mt-5
                v-spacer
                v-col(cols="auto")
                  v-btn(
                    depressed
                    color="primary"
                    @click="step--"
                  )
                    span Voltar

                v-col(cols="auto")
                  v-dialog(
                    v-model="flags.sendConfirmation"
                    width="300"
                  )
                    template(v-slot:activator="{ on }")
                      v-btn(
                        depressed
                        color="success"
                        @click="validate()"
                        :loading="loadings.send"
                      )
                        span Enviar
                    v-card
                      v-card-title.headline Deseja confirmar o envio desta notificação?
                      v-card-text Esta notificação possui {{ recipients.length }} destinatários
                      v-card-actions
                        v-spacer
                        v-btn(
                          depressed
                          @click="flags.sendConfirmation = false"
                        ) Não
                        v-btn(
                          depressed
                          color="success"
                          @click="flags.sendConfirmation = false; send()"
                        ) Sim

</template>

<script>
import { mapState } from 'vuex'
import notificationsApi from '../api/notification'

export default {
  data: function () {
    return {
      step: 1,
      formmatedDate: this.$moment().format('DD MMM. YYYY'),
      loadings: {
        send: false
      },
      form: {
        recipients: [],
        title: '',
        body: '',
        time: this.$moment().format('HH:mm'),
        date: this.$moment().format('YYYY-MM-DD'),
        usersTypes: [],
        groupsTypes: [],
        groups: [],
        groupsUsers: [],
        users: [],
        valid: true
      },
      filtered: {
        groups: []
      },
      flags: {
        time: false,
        date: false,
        sendConfirmation: false,
        snackbar: false
      },
      rules: {
        recipients: () => this.recipients.length > 0 || 'Nenhum destinatário selecionado',
        required: v => !!v || 'O campo não pode estar vazio',
        max: v => v.length < 45 || 'O campo não pode conter mais que 45 caracteres'
      },
      snackbar: {
        text: ''
      }
    }
  },
  watch: {
    'form.date': function (date) {
      this.formmatedDate = this.$moment(date).format('DD MMM. YYYY')
    },
    'form.groupsTypes': function () {
      const form = this.form.groupsTypes.map(index => this.groups.types[index])
      if (form.length) {
        this.filtered.groups = this.groups.groups.filter(group => form.map(group => group.slug).includes(group.type))
      } else {
        this.filtered.groups = [...this.groups.groups]
      }
    }
  },
  computed: {
    ...mapState(['users', 'groups']),
    recipientsData () {
      return this.form.groupsUsers.map(recipient => {
        const data = { ...recipient }
        const type = this.users.types.find(type => type.slug === data.type)

        data.type = type.name

        return data
      })
    },
    recipients () {
      return [...this.form.groupsUsers, ...this.form.users]
    }
  },
  methods: {
    select () {
      this.form.groupsUsers = this.selectByGroups(this.form.groups)
      if (this.form.groupsUsers.length && this.form.usersTypes.length) {
        this.form.groupsUsers = this.form.groupsUsers.filter(user => this.form.usersTypes.includes(user.type))
      } else if (this.form.usersTypes.length) {
        this.form.groupsUsers = this.users.users.filter(user => this.form.usersTypes.includes(user.type))
      }
    },
    selectByGroups (groups = []) {
      // This method only consider groups as group cods
      const form = []

      if (groups.length) {
        form.push(...this.users.users.filter(user => {
          // Get user' groups ancestors
          const ancestors = new Set()
          user.groups.forEach(group => {
            const groupAncestors = this.getGroupAncestors(group)
            groupAncestors.forEach(group => {
              ancestors.add(group)
            })
          })

          // Get intersection between groups accessible by the user (its groups and ancestors)
          const accessibleGroups = new Set([...(Array.from(ancestors)), ...user.groups])
          const interserction = groups.filter(group => accessibleGroups.has(group))

          return interserction.length > 0
        }))
      }

      return form
    },
    getGroupAncestors (cod) {
      const ancestors = []
      const node = this.groups.groups.find(group => group.cod === cod)
      let parent = this.groups.groups.find(group => group.cod === node.parent_cod)

      while (parent) {
        ancestors.push(parent.cod)
        parent = this.groups.groups.find(group => group.cod === parent.parent_cod)
      }

      return ancestors
    },
    validate () {
      if (this.$refs.form.validate()) {
        this.flags.sendConfirmation = true
      } else {
        this.snackbar.text = 'Preencha todos os campos corretamente.'
        this.flags.snackbar = true
      }
    },
    async send () {
      this.loadings.send = true

      try {
        await notificationsApi.createNotification({
          title: this.form.title,
          body: this.form.body,
          recipients: this.recipients.map(recipient => recipient.id),
          publication_time: this.$moment(`${this.form.date} ${this.form.time}`).format('YYYY-MM-DD HH:mm:ss.SSSSZ')
        })

        this.loadings.send = false

        this.$emit('created')
      } catch (err) {
        this.snackbar.text = 'Erro ao criar a notificação.'
      }

      this.flags.snackbar = true
    }
  },
  mounted () {
    this.filtered.groups = [ ...this.groups.groups ]
  }
}
</script>
