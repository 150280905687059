<template lang="pug">
v-row
  v-col.mb-0.pb-0
    v-text-field(
      v-model="searchText"
      :label="searchLabel"
      prepend-inner-icon="search"
      rounded
    )
  v-col(cols=12 v-if="items.length > 0").mb-5.mt-0.pt-0
    perfect-scrollbar(@ps-y-reach-end="getNextPage")
      v-list(:max-height="maxHeight")
        v-list-item(v-for="(item, index) of items" :key="`list-item-${index}`")
          v-list-item-avatar
            v-icon {{ itemIcon }}
          v-list-item-content
            v-row
              v-col(:cols="secondaryItemText ? 6 : 12") {{ item[primaryItemText] }}
              v-col(v-if="secondaryItemText" cols=6) {{ item[secondaryItemText]}}
          v-list-item-action-text
            v-btn(
              icon
              @click="removeItem(item)"
            )
              v-icon remove_circle
  v-col(cols=12 v-if="loadings.search || loadings.pagination")
    v-row(justify="center" align="center")
      v-progress-circular(indeterminate color="primary")
  v-col(cols=12 v-if="items.length === 0 && !loadings.search && !loadings.pagination")
    span.subtitle-3 {{ noItemMessage }}
</template>

<script>
import _ from 'lodash'
import { createModuleLogger } from '../configs/winston'

const l = createModuleLogger('List')

export default {
  props: [
    'action',
    'offset',
    'params',
    'searchLabel',
    'noItemMessage',
    'itemIcon',
    'itemValue',
    'primaryItemText',
    'secondaryItemText',
    'maxHeight'
  ],
  data: function () {
    return {
      items: [],
      loadings: {
        search: false,
        pagination: false
      },
      flags: {
        lastPage: false
      },
      searchText: null,
      next: 0
    }
  },
  created () {
    this.debouncedsearchItems = _.debounce(this.searchItems, 400)
    this.debouncedsearchItems()
  },
  watch: {
    searchText (text) {
      this.debouncedsearchItems(text)
    }
  },
  methods: {
    async searchItems (text) {
      l.info('Will search items')

      this.items = []
      this.next = 0
      this.flags.lastPage = false

      this.loadings.search = true
      const result = await this.$store.dispatch(this.action, {
        next: this.next,
        offset: this.offset,
        search: text || null,
        ...this.params
      })
      this.loadings.search = false

      if (result.length > 0 && (text === this.searchText || (!text && !this.searchText))) {
        l.info('Pushing result')

        this.items = result
      } else if (text !== this.searchText) {
        l.info('Search text changed')
      } else {
        l.info('No search result')
      }
    },
    async getNextPage () {
      if (!this.loadings.pagination && !this.loadings.search && !this.flags.lastPage) {
        l.info('Getting next page')

        this.next = this.items[this.items.length - 1].id + 1

        this.loadings.pagination = true
        const result = await this.$store.dispatch(this.action, {
          next: this.next,
          offset: this.offset,
          search: this.searchText || null,
          ...this.params
        })
        this.loadings.pagination = false

        if (this.next > 0 && result.length > 0) {
          l.info('Pushing page')

          this.items.push(...result)
        } else if (this.next > 0 && result.length === 0) {
          l.info('Got empty page')

          this.flags.lastPage = true
        }
      }
    },
    removeItem (item) {
      this.items = this.items.filter(m => m[this.itemValue] !== item[this.itemValue])
      this.$emit('removed', item)
    }
  }
}
</script>
